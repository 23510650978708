import React from "react";
import HtmlCssImg from "../assets/images/html-css.png";
import PhotoShopImg from "../assets/images/photoshop-img.png";
import PremiereImg from "../assets/images/premiere.png";
import ElementorImg from "../assets/images/elementor.png";
import CanvaImg from "../assets/images/canva.png";
import HtmlCssLogo from "../assets/images/tech-logos/html-css.png";

const Resume = () => {
  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Education.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Diploma in Informatica e Telecomunicazioni</p>
                <p className="cursus university">
                  ITIS Leonardo Da Vinci, Rimini / 2006 - 2011
                </p>
                <p className="cursus">
                  Technical high school diploma focused on computer science and telecommunications, providing a strong foundation in technology and programming.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Experience.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Social Media Specialist & Master Trainer</p>
                <p className="cursus university">Corsi.it / 2024 - Present</p>
                <p className="cursus">
                  Leading social media strategies and conducting master classes in social media marketing. Managing and optimizing advertising campaigns for online courses.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Ads & Marketing Specialist</p>
                <p className="cursus university">Trading Capital srl / 2019 - 2024</p>
                <p className="cursus">
                  Managed high-budget advertising campaigns for e-commerce and affiliate marketing. Optimized performance across Facebook, Google Ads, and other platforms with budgets exceeding hundreds of thousands of euros.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Social Media Manager</p>
                <p className="cursus university">Promozione Alberghiera / 2018</p>
                <p className="cursus">
                  Developed social media strategies to increase brand awareness and engagement. Created original content using tools like Hootsuite and monitored performance through Google Analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Skills & Expertise
          </h2>
          <div className="line"></div>
        </div>
        <div className="skills-grid">
          <div className="skill-item fade_up">
            <h3 className="bachelor">WordPress Development</h3>
            <p className="cursus">Expert in theme development, custom plugins, and site optimization</p>
          </div>
          <div className="skill-item fade_up">
            <h3 className="bachelor">Web Development</h3>
            <p className="cursus">HTML, CSS, JavaScript, and modern web technologies</p>
          </div>
          <div className="skill-item fade_up">
            <h3 className="bachelor">Digital Marketing</h3>
            <p className="cursus">Meta Ads, Google Ads, campaign optimization</p>
          </div>
          <div className="skill-item fade_up">
            <h3 className="bachelor">Social Media</h3>
            <p className="cursus">Strategy, content creation, and community management</p>
          </div>
          <div className="skill-item fade_up">
            <h3 className="bachelor">SEO & Analytics</h3>
            <p className="cursus">Technical SEO, performance tracking, data analysis</p>
          </div>
          <div className="skill-item fade_up">
            <h3 className="bachelor">AI & Automation</h3>
            <p className="cursus">AI tools integration and workflow automation</p>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Design Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main flip_up">
            <img className="html-css-logo" src={HtmlCssLogo} alt="HTML/CSS" />
            <div className="skill-counter-main">
              <p>94%</p>
              <p>HTML, CSS</p>
            </div>
          </div>
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhotoShopImg} alt="photoshop" />
            <div className="skill-counter-main photoshop-text">
              <p>98%</p>
              <p>PHOTOSHOP</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={PremiereImg} alt="premiere" className="brand-logos" />
            <div className="skill-counter-main adobe-xd-text">
              <p>85%</p>
              <p>PREMIERE</p>
            </div>
          </div>
          <div className="design-skills-img-main sketch flip_up">
            <img src={ElementorImg} alt="elementor" className="brand-logos" />
            <div className="skill-counter-main sketch-text">
              <p>95%</p>
              <p>ELEMENTOR</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={CanvaImg} alt="canva" className="brand-logos" />
            <div className="skill-counter-main invision-text">
              <p>92%</p>
              <p>CANVA</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Design Skill End ===================================== --> */}
    </>
  );
};
export default Resume;
