import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      {/* <!-- ====================================== Section Services ===================================== --> */}
      <section className="Services-section" id="services">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Services.
          </h2>
          <div className="line"></div>
        </div>
        <h3 className="services-product-text fade_up">
          Empowering businesses with cutting-edge digital solutions and proven marketing strategies
        </h3>
        <p className="visoion-text fade_up">
          Delivering exceptional results through a blend of technical expertise and creative innovation
        </p>
        <div className="row services-row">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.33334L35 11.6667V28.3333L20 36.6667L5 28.3333V11.6667L20 3.33334Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <p className="services-text">Digital Marketing Expert</p>
              <p className="molestie">
                Specialized in high-ROI advertising campaigns across Meta Ads, Google Ads, and TikTok Ads. Managing budgets exceeding €100k with proven success in e-commerce and affiliate marketing.
              </p>
              <div className="down-arrow-svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img2">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.3333 5H6.66667C5.74619 5 5 5.74619 5 6.66667V33.3333C5 34.2538 5.74619 35 6.66667 35H33.3333C34.2538 35 35 34.2538 35 33.3333V6.66667C35 5.74619 34.2538 5 33.3333 5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <p className="services-text services-text2">Social Media Strategy</p>
              <p className="molestie">
                Comprehensive social media management including content creation, community engagement, and performance analytics. Expert in building brand presence and driving engagement across platforms.
              </p>
              <div className="down-arrow-svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img3">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33334 20 3.33334C10.7952 3.33334 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <p className="services-text services-text3">AI Integration & Automation</p>
              <p className="molestie">
                Leveraging cutting-edge AI tools to optimize workflows and enhance business operations. Expert in implementing AI solutions for content creation, customer service, and marketing automation.
              </p>
              <div className="down-arrow-svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img4">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.3333 5H6.66667C5.74619 5 5 5.74619 5 6.66667V33.3333C5 34.2538 5.74619 35 6.66667 35H33.3333C34.2538 35 35 34.2538 35 33.3333V6.66667C35 5.74619 34.2538 5 33.3333 5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <p className="services-text services-text4">Web Development & Design</p>
              <p className="molestie">
                Creating modern, responsive websites with WordPress and custom solutions. Expert in Elementor, theme customization, and integrating marketing tools for optimal conversion.
              </p>
              <div className="down-arrow-svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Services End ===================================== --> */}
      {/* <!-- ====================================== Section Services Pop Up ===================================== --> */}
      <div id="services-popup" className="popup-container">
        <div className="popup-content popup-content2">
          <Link to="#" className="close">
            &times;
          </Link>
          <h2 className="simple-steps">Digital Marketing Expert</h2>
          <p className="blog-sit-text">
            As a seasoned digital marketing expert, I specialize in creating and managing high-performance advertising campaigns across multiple platforms. My expertise includes:
          </p>
          <p className="blog-sit-text">
            • Meta Ads optimization and scaling for e-commerce and affiliate marketing
            • Google Ads campaign management with proven ROI
            • TikTok Ads strategy and implementation
            • Data-driven decision making and performance analysis
            • Budget management exceeding €100k with consistent returns
          </p>
          <h2 className="simple-steps services-popup-text">Why Choose My Services?</h2>
          <p className="blog-sit-text">
            I combine technical expertise with creative strategy to deliver results that exceed expectations. My approach focuses on:
          </p>
          <p className="blog-sit-text">
            • Comprehensive market analysis and competitor research
            • Custom targeting and audience optimization
            • Regular performance monitoring and optimization
            • Transparent reporting and communication
            • Continuous testing and improvement
          </p>
        </div>
      </div>
      {/* <!-- ====================================== Services PopUp End ===================================== --> */}
    </>
  );
}
export default Services;
